var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3",attrs:{"flat":""}},[_c('p',{staticClass:"clav-content-title-1"},[_vm._v("Administração")]),_c('p',{staticClass:"clav-content-text px-8 py-2"},[_vm._v(" Área reservada à administração e gestão, sendo possível fazer operações na própria plataforma e API de dados, ou também exportar/eliminar diversas coleções. ")]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","justify":"center","align":"center"}},[(
          this.$userLevel() === 7 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/gestao/administracao')}}},[_c('unicon',{attrs:{"name":"admin-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.717","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Área Administrativa")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","justify":"center","align":"center"}},[(
          this.$userLevel() === 7 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/gestao/alterarParametros')}}},[_c('unicon',{attrs:{"name":"api-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.71","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Alterar Parâmetros API Dados")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","justify":"center","align":"center"}},[(
          this.$userLevel() === 7 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text clav-linear-background",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },attrs:{"rounded":""},on:{"click":function($event){return _vm.$router.push('/gestao/exportarColecoes')}}},[_c('unicon',{attrs:{"name":"exportar-icon","width":"20","height":"20","viewBox":"0 0 20.71 18.001","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Exportar Coleções")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","justify":"center","align":"center"}},[(
          this.$userLevel() === 7 &&
          this.$store.state.token != '' &&
          this.$store.state.name != ''
        )?_c('v-btn',{staticClass:"white--text",class:{
          'px-8': _vm.$vuetify.breakpoint.lgAndUp,
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
        },staticStyle:{"box-shadow":"0 4px 6px -1px rgba(0, 0, 0, 0.4),","outline":"none !important"},attrs:{"color":"red darken-4","rounded":""},on:{"click":function($event){return _vm.$router.push('/gestao/apagarColecoes')}}},[_c('unicon',{attrs:{"name":"eliminar-icon","width":"20","height":"20","viewBox":"0 0 20.71 20.71","fill":"#ffffff"}}),_c('p',{staticClass:"ml-2"},[_vm._v("Apagar Coleções")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }